<template>
    <div>
        <b-card>
            <h2>Listagem</h2>
            <vue-good-table
                :columns="columns"
                :rows="rows"
                :sortOptions="{
                    enabled: true,
                    initialSortBy: { field: 'translatedName', type: 'asc' },
                }"
                @on-row-click="onRowClick"
            >
            </vue-good-table>
        </b-card>
    </div>
</template>

<script>
import VueGoodTable from "vue-good-table/src/components/Table.vue"
import Api from "@/services/api/"
import { BCard } from "bootstrap-vue"

export default {
    components: { BCard, VueGoodTable },
    data() {
        return {
            columns: [
                {
                    label: this.$t("modules.systemRecords.recordName"),
                    field: "translatedName",
                    type: "text",
                },
            ],
            rows: [],
        }
    },
    mounted() {
        // gets data using cache busting
        Api.findAll("system-records", { t: Date.now() }).then(response => {
            this.rows = response.data.map(systemRecord => ({
                id: systemRecord.id,
                translatedName: this.$t(
                    `modules.systemRecords.ids.${systemRecord.id}`
                ),
            }))
        })
    },
    methods: {
        onRowClick(params) {
            this.$router.push({
                name: "list-system-record-items",
                params: { name: params.row.id },
            })
        },
    },
}
</script>

<style lang="scss" scoped></style>
